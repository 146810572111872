import * as requestDocumentationStatuses from 'constants/enums/requestDocumentationStatuses'

export default {
  [requestDocumentationStatuses.NEW]: 'Nová ',
  [requestDocumentationStatuses.COMPLETION]: 'K dokončení',
  [requestDocumentationStatuses.GENERATION]: 'Generování',
  [requestDocumentationStatuses.PROCESSING]: 'Ve zpracovaní',
  [requestDocumentationStatuses.CLOSED]: 'Uzavřená',
  [requestDocumentationStatuses.CANCELLED]: 'Zrušená',
}
